@charset "UTF-8";
/* You can add global styles to this file, and also import other style files */
@import "~@angular/material/prebuilt-themes/indigo-pink.css";
@import 'material-icons/iconfont/material-icons.css';
@font-face {
  font-family: "bocc-icons";
  src: url("./assets/fonts/bocc-icons/bocc-icons.eot");
  src: url("./assets/fonts/bocc-icons/bocc-icons.eot") format("embedded-opentype"), url("./assets/fonts/bocc-icons/bocc-icons.svg") format("svg"), url("./assets/fonts/bocc-icons/bocc-icons.ttf") format("truetype"), url("./assets/fonts/bocc-icons/bocc-icons.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}
@font-face {
  font-family: "Gelion";
  src: url("./assets/fonts/Gelion-Black/Gelion-Black.eot");
  src: url("./assets/fonts/Gelion-Black/Gelion-Black.eot") format("embedded-opentype"), url("./assets/fonts/Gelion-Black/Gelion-Black.ttf") format("truetype"), url("./assets/fonts/Gelion-Black/Gelion-Black.woff") format("woff"), url("./assets/fonts/Gelion-Black/Gelion-Black.woff2") format("woff2");
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Gelion";
  src: url("./assets/fonts/Gelion-Bold/Gelion-Bold.eot");
  src: url("./assets/fonts/Gelion-Bold/Gelion-Bold.eot") format("embedded-opentype"), url("./assets/fonts/Gelion-Bold/Gelion-Bold.ttf") format("truetype"), url("./assets/fonts/Gelion-Bold/Gelion-Bold.woff") format("woff"), url("./assets/fonts/Gelion-Bold/Gelion-Bold.woff2") format("woff2");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Gelion";
  src: url("./assets/fonts/Gelion-Light/Gelion-Light.eot");
  src: url("./assets/fonts/Gelion-Light/Gelion-Light.eot") format("embedded-opentype"), url("./assets/fonts/Gelion-Light/Gelion-Light.ttf") format("truetype"), url("./assets/fonts/Gelion-Light/Gelion-Light.woff") format("woff"), url("./assets/fonts/Gelion-Light/Gelion-Light.woff2") format("woff2");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Gelion";
  src: url("./assets/fonts/Gelion-Medium/Gelion-Medium.eot");
  src: url("./assets/fonts/Gelion-Medium/Gelion-Medium.eot") format("embedded-opentype"), url("./assets/fonts/Gelion-Medium/Gelion-Medium.ttf") format("truetype"), url("./assets/fonts/Gelion-Medium/Gelion-Medium.woff") format("woff"), url("./assets/fonts/Gelion-Medium/Gelion-Medium.woff2") format("woff2");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Gelion";
  src: url("./assets/fonts/Gelion-Regular/Gelion-Regular.eot");
  src: url("./assets/fonts/Gelion-Regular/Gelion-Regular.eot") format("embedded-opentype"), url("./assets/fonts/Gelion-Regular/Gelion-Regular.ttf") format("truetype"), url("./assets/fonts/Gelion-Regular/Gelion-Regular.woff") format("woff"), url("./assets/fonts/Gelion-Regular/Gelion-Regular.woff2") format("woff2");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Gelion";
  src: url("./assets/fonts/Gelion-SemiBold/Gelion-SemiBold.eot");
  src: url("./assets/fonts/Gelion-SemiBold/Gelion-SemiBold.eot") format("embedded-opentype");
  src: url("./assets/fonts/Gelion-SemiBold/Gelion-SemiBold.ttf") format("truetype"), url("./assets/fonts/Gelion-SemiBold/Gelion-SemiBold.woff") format("woff"), url("./assets/fonts/Gelion-SemiBold/Gelion-SemiBold.woff2") format("woff2");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Gelion";
  src: url("./assets/fonts/Gelion-Thin/Gelion-Thin.eot");
  src: url("./assets/fonts/Gelion-Thin/Gelion-Thin.eot") format("embedded-opentype"), url("./assets/fonts/Gelion-Thin/Gelion-Thin.ttf") format("truetype"), url("./assets/fonts/Gelion-Thin/Gelion-Thin.woff") format("woff"), url("./assets/fonts/Gelion-Thin/Gelion-Thin.woff2") format("woff2");
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "icons";
  src: url("./assets/fonts/icons/icons.eot") format("eot");
  src: url("./assets/fonts/icons/icons.svg") format("svg"), url("./assets/fonts/icons/icons.ttf") format("truetype"), url("./assets/fonts/icons/icons.woff") format("woff");
}
[class^=bocc-], [class*=" bocc-"] {
  font-family: "bocc-icons" !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.bocc-alert-triangle:before {
  content: "";
}

.bocc-x:before {
  content: "";
}

.bocc-youtube:before {
  content: "";
}

.bocc-linkedin2:before {
  content: "";
}

.bocc-twitter:before {
  content: "";
}

.bocc-facebook:before {
  content: "";
}

.alert {
  position: relative;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background: #f2f2f2;
  padding: 20px;
  border-radius: 0 8px 8px 0;
  font-family: "Gelion", sans-serif;
  font-weight: normal;
  line-height: 25px;
  border-left: 3px solid #008ACC;
  margin: 5px 0;
}

@media screen and (max-width: 959px) {
  .alert {
    width: calc(100% - 2px) !important;
    margin-left: 10px;
  }
}
@media screen and (min-width: 960px) {
  .alert {
    width: calc(100% + 18px) !important;
  }
}
.alert-caption p {
  font-size: 16px;
}

.alert-caption p a {
  text-decoration: none;
  font-weight: 600;
  color: #008ACC;
}

.alert .icon-alert {
  font-size: 40px;
  color: #002449;
}

.alert-caption p b {
  color: #008ACC;
}

.alert .icon-close {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 2;
  padding: 1.25rem 1rem;
}

.btn-nano-gray-light {
  width: 40px;
  height: 40px;
  line-height: 40px;
  outline: none;
  display: inline-block;
  text-decoration: none;
  background: #E5E5E5;
  color: #FFF;
  border: 0;
  text-align: center;
  border-radius: 50%;
  cursor: pointer;
  color: #002449;
}

.alert-list-nb {
  list-style: none;
  padding: 0;
  margin: 0;
}

.alert-list-nb .alert-item-nb {
  font-family: "Gelion", sans-serif;
  font-size: 16px;
  font-weight: 400;
}

.button {
  font-family: "Gelion", sans-serif;
  font-size: 16px;
  font-weight: normal;
  text-align: center;
  display: inline-block;
  outline: none;
  border-radius: 5px;
  padding: 10px 35px;
  -webkit-transition: 0.7s;
  transition: 0.7s;
  text-decoration: none;
  cursor: pointer;
}

.button-border {
  border-style: none !important;
}

@media screen and (max-width: 959px) {
  .button-border {
    margin-left: 10px;
  }
}
.button-primary {
  color: #FFF !important;
  background-color: #008ACC;
  border: 2px solid #008ACC;
  -webkit-transition: 0.7s;
  transition: 0.7s;
}

.button-disabled {
  color: #FFF !important;
  background-color: #939393;
  border: 2px solid #939393;
  -webkit-transition: 0.7s;
  transition: 0.7s;
}

::ng-deep .mat-form-field {
  font-family: "Gelion", sans-serif !important;
}

::ng-deep .mat-form-field-appearance-legacy .mat-form-field-underline {
  height: 0px !important;
  bottom: 0px !important;
}

::ng-deep .mat-form-field-infix {
  padding: 0px !important;
  border-top: 0px !important;
}

::ng-deep .mat-form-field-appearance-legacy .mat-form-field-wrapper {
  padding-bottom: 0px !important;
}

::ng-deep .mat-form-field-appearance-legacy .mat-form-field-infix {
  padding: 0px !important;
}

::ng-deep .mat-form-field-underline {
  display: none !important;
}

::ng-deep .mat-form-field-hint-wrapper {
  display: none !important;
}

::ng-deep .mat-form-field-subscript-wrapper {
  display: none !important;
}

::ng-deep .mat-input-element {
  border: 1px solid #008ACC !important;
  color: #002449 !important;
  width: 100%;
  border: none;
  border-radius: 5px;
  padding: 8px !important;
  background: #FFF;
  min-width: 100%;
  min-height: 100%;
  max-width: none !important;
  max-height: none !important;
}

.mat-input-element-input {
  height: 22px !important;
}

.mat-input-element-textarea {
  min-height: 66px !important;
  resize: both !important;
}

.fileUpGroup {
  position: relative;
  height: 60px;
  cursor: pointer;
  margin: 5px 0;
}

@media screen and (max-width: 959px) {
  .fileUpGroup {
    width: calc(100% - 2px) !important;
    margin-left: 10px;
  }
}
@media screen and (min-width: 960px) {
  .fileUpGroup {
    width: calc(100% + 18px) !important;
  }
}
.fileUpGroup input {
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 3;
  height: 40px;
  margin: 0;
  padding: 0;
  display: block;
  cursor: pointer;
  width: 100%;
}

.fileUpGroup::before {
  content: attr(data-textButton);
  position: absolute;
  top: 0;
  left: 0;
  display: inline-block;
  background: #008ACC;
  color: #FFF;
  z-index: 2;
  line-height: 40px;
  padding: 0 35px;
  pointer-events: none;
  border-radius: 5px 0 0 5px;
}

.fileUpGroup::after {
  content: attr(data-SelectFile);
  position: absolute;
  display: block;
  top: 0;
  right: 0;
  width: calc(100% - 40px);
  height: 40px;
  font-size: 16px;
  font-weight: normal;
  text-align: center;
  padding: 0px 15px;
  background: #E5E5E5;
  border-radius: 5px;
  line-height: 40px;
  color: #008ACC;
  z-index: 1;
  pointer-events: none;
}

::ng-deep .mat-select {
  font-family: "Gelion", sans-serif !important;
  width: calc(100% + 18px) !important;
  border: 1px solid #008ACC !important;
  border-radius: 5px !important;
  height: 40px !important;
  padding: 8px !important;
  padding-right: 0px !important;
  background: #FFF !important;
  color: #002449 !important;
}

::ng-deep .mat-select-arrow {
  opacity: 0;
}

::ng-deep .mat-select-arrow-wrapper {
  padding-left: 28px !important;
  background-image: url(./assets/img/icon-desplegar.png) !important;
  background-repeat: no-repeat !important;
  background-position-y: top;
  background-position-x: right;
}

::ng-deep .mat-select-panel {
  border-radius: none !important;
  border: 1px solid #737373 !important;
}

::ng-deep .mat-select-panel .mat-option {
  background-color: white;
  border: none !important;
}

::ng-deep .mat-select-panel .mat-option.mat-selected:not(.mat-option-multiple) {
  background: rgba(0, 0, 0, 0.12);
  font-style: italic;
}

::ng-deep .mat-option {
  background-color: #fff;
  color: #002449 !important;
}

::ng-deep .mat-option-text:hover:not(.mat-option-disabled) {
  font-family: "Gelion", sans-serif !important;
  font-size: 16px;
  background: #008acc !important;
  color: white;
}
::ng-deep .mat-option:hover {
  font-family: "Gelion", sans-serif !important;
  font-size: 16px;
  background: #008acc !important;
  color: white !important;
}

@media screen and (max-width: 959px) {
  .padding-form {
    padding: 10px !important;
  }
}
@media screen and (min-width: 960px) {
  .padding-form {
    padding: 40px !important;
    padding-right: 30px !important;
  }
}
.ml-1 {
  margin-left: 8px !important;
}

.my-1 {
  margin-top: 8px !important;
  margin-bottom: 8px !important;
}

.mb-3 {
  margin-bottom: 24px !important;
}

.text-secondary {
  color: #002449 !important;
}

.sub-title-1 {
  font-family: "Gelion", sans-serif;
  font-size: 20px;
  font-weight: 400;
  line-height: 28px;
  margin: 0px;
}

.sub-title-2 {
  font-family: "Gelion", sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  margin-top: 0px !important;
}

.font-weight-bold {
  font-weight: 700 !important;
}

.font-italic {
  font-style: italic !important;
}

.headline-3 {
  font-family: "Gelion", sans-serif;
  font-size: 28px;
  font-weight: 400;
  line-height: 40px;
}

.text-uppercase {
  text-transform: uppercase !important;
}

h2 {
  display: block;
  font-size: 1.5em;
  margin-block-start: 0.83em;
  margin-block-end: 0.83em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  font-weight: bold;
}

h3 {
  display: block;
  font-size: 1.17em;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  font-weight: bold;
}

h4 {
  display: block;
  margin-block-start: 1.33em;
  margin-block-end: 1.33em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  font-weight: bold;
}

div {
  display: block;
}

a {
  background-color: transparent;
}

.form-group {
  position: relative;
  padding: 8px 0px;
}

@media (max-width: 959px) {
  .form-group {
    padding: 2% 10px;
  }
}
.form-group .label {
  display: block;
  position: absolute;
  top: 0px;
  left: 16px;
  background: #FFF;
  padding: 0 5px;
  color: #898D95;
  font-family: "Gelion", sans-serif;
  font-weight: 400;
  font-size: inherit;
}

.form-group.active .label {
  color: #008ACC !important;
}

label {
  cursor: default;
}

.form-group .input {
  width: 100%;
  border: none;
  border: 1px solid #E5E5E5;
  border-radius: 5px;
  height: 40px;
  padding: 8px;
  background: #FFF;
  color: #898D95;
}

button, input {
  overflow: visible;
}

button, input, optgroup, select, textarea {
  font-family: inherit;
  font-size: 100%;
  line-height: 1.15;
  margin: 0;
}

/*
 *  Owl Carousel - Core
 */
.owl-carousel {
  display: none;
  width: 100%;
  -webkit-tap-highlight-color: transparent;
  /* position relative and z-index fix webkit rendering fonts issue */
  position: relative;
  z-index: 1;
}
.owl-carousel .owl-stage {
  position: relative;
  -ms-touch-action: pan-Y;
  touch-action: manipulation;
  -moz-backface-visibility: hidden;
  /* fix firefox animation glitch */
}
.owl-carousel .owl-stage:after {
  content: ".";
  display: block;
  clear: both;
  visibility: hidden;
  line-height: 0;
  height: 0;
}
.owl-carousel .owl-stage-outer {
  position: relative;
  overflow: hidden;
  /* fix for flashing background */
  -webkit-transform: translate3d(0px, 0px, 0px);
}
.owl-carousel .owl-wrapper,
.owl-carousel .owl-item {
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -ms-backface-visibility: hidden;
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
}
.owl-carousel .owl-item {
  position: relative;
  min-height: 1px;
  float: left;
  -webkit-backface-visibility: hidden;
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
}
.owl-carousel .owl-item.center {
  z-index: 5;
}
.owl-carousel .owl-item img {
  display: block;
  width: 100%;
}
.owl-carousel .owl-nav.disabled,
.owl-carousel .owl-dots.disabled {
  display: none;
}
.owl-carousel .owl-nav .owl-prev,
.owl-carousel .owl-nav .owl-next,
.owl-carousel .owl-dot {
  cursor: pointer;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.owl-carousel .owl-nav button.owl-prev,
.owl-carousel .owl-nav button.owl-next,
.owl-carousel button.owl-dot {
  background: none;
  color: inherit;
  border: none;
  padding: 0 !important;
  font: inherit;
}
.owl-carousel.owl-loaded {
  display: block;
}
.owl-carousel.owl-loading {
  opacity: 0;
  display: block;
}
.owl-carousel.owl-hidden {
  opacity: 0;
}
.owl-carousel.owl-refresh .owl-item {
  visibility: hidden;
}
.owl-carousel.owl-drag .owl-item {
  touch-action: pan-y;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.owl-carousel.owl-grab {
  cursor: move;
  cursor: grab;
}
.owl-carousel.owl-rtl {
  direction: rtl;
}
.owl-carousel.owl-rtl .owl-item {
  float: right;
}

/* No Js */
.no-js .owl-carousel {
  display: block;
}

/*
 *  Owl Carousel - Animate Plugin
 */
.owl-carousel .animated {
  animation-duration: 1000ms;
  animation-fill-mode: both;
}
.owl-carousel .owl-animated-in {
  z-index: 0;
}
.owl-carousel .owl-animated-out {
  z-index: 1;
}
.owl-carousel .fadeOut {
  animation-name: fadeOut;
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
/*
 * 	Owl Carousel - Auto Height Plugin
 */
.owl-height {
  transition: height 500ms ease-in-out;
}

/*
 * 	Owl Carousel - Lazy Load Plugin
 */
.owl-carousel .owl-item {
  /**
  	This is introduced due to a bug in IE11 where lazy loading combined with autoheight plugin causes a wrong
  	calculation of the height of the owl-item that breaks page layouts
   */
}
.owl-carousel .owl-item .owl-lazy {
  transition: opacity 400ms ease;
}
.owl-carousel .owl-item .owl-lazy[src^=""], .owl-carousel .owl-item .owl-lazy:not([src]) {
  max-height: 0;
}
.owl-carousel .owl-item img.owl-lazy {
  transform-style: preserve-3d;
}

/*
 * 	Default theme - Owl Carousel CSS File
 */
.owl-theme .owl-nav {
  margin-top: 10px;
  text-align: center;
  -webkit-tap-highlight-color: transparent;
}
.owl-theme .owl-nav [class*=owl-] {
  color: #FFF;
  font-size: 14px;
  margin: 5px;
  padding: 4px 7px;
  background: #D6D6D6;
  display: inline-block;
  cursor: pointer;
  border-radius: 3px;
}
.owl-theme .owl-nav [class*=owl-]:hover {
  background: #869791;
  color: #FFF;
  text-decoration: none;
}
.owl-theme .owl-nav .disabled {
  opacity: 0.5;
  cursor: default;
}
.owl-theme .owl-nav.disabled + .owl-dots {
  margin-top: 10px;
}
.owl-theme .owl-dots {
  text-align: center;
  -webkit-tap-highlight-color: transparent;
}
.owl-theme .owl-dots .owl-dot {
  display: inline-block;
  zoom: 1;
  *display: inline;
}
.owl-theme .owl-dots .owl-dot span {
  width: 10px;
  height: 10px;
  margin: 5px 7px;
  background: #D6D6D6;
  display: block;
  -webkit-backface-visibility: visible;
  transition: opacity 200ms ease;
  border-radius: 30px;
}
.owl-theme .owl-dots .owl-dot.active span, .owl-theme .owl-dots .owl-dot:hover span {
  background: #869791;
}

html {
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
}

:root {
  --font-size: 1;
  --algo: 0px !important;
}

body {
  font-family: "Gelion", sans-serif;
  background: #FFF !important;
  font-size: calc(var(--font-size) * 1em);
  position: relative;
  padding-bottom: 13.15em;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
}

.text-primary {
  color: #008ACC !important;
}

.separator-header {
  height: 4px;
  clear: both;
}

.separator-footer {
  height: 16px;
  clear: both;
}

input.invalid.mat-input-element {
  border-color: red !important;
}

.invalid-textarea.mat-input-element-textarea {
  border-color: red !important;
}

.invalid-input {
  border: 1px solid red;
}

.invalid-text {
  color: red;
  font-size: small;
}

.alert-text {
  color: red;
  margin-top: -17px;
  position: relative;
  font-size: small;
}

.fileUpGroup {
  margin-bottom: 10px;
}

.file-list {
  margin-top: 5px;
}

.file-item {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
}

.file-info {
  display: flex;
  align-items: center;
}

.delete-button {
  color: #008ACC;
  margin-left: 10px;
  /* Ajusta el margen según sea necesario */
  background: none;
  /* Elimina el fondo del botón */
  border: none;
  /* Elimina el borde del botón */
  cursor: pointer;
  /* Cambia el cursor al pasar sobre el botón */
  padding: 0;
}

.file-info mat-icon {
  margin-right: 5px;
  /* O ajusta según sea necesario */
}

.file-item:hover .file-info {
  color: #008ACC;
}