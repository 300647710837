

[class^="bocc-"], [class*=" bocc-"] {
  font-family: 'bocc-icons' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.bocc-alert-triangle:before {
  content: "\e930";
}

.bocc-x:before {
  content: "\e91f";
}

.bocc-youtube:before {
  content: "\ea9d";
}

.bocc-linkedin2:before {
  content: "\eaca";
}

.bocc-twitter:before {
  content: "\ea96";
}

.bocc-facebook:before {
  content: "\ea90";
}

