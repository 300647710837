
.alert {
  position: relative;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background: #f2f2f2;
  padding: 20px;
  border-radius: 0 8px 8px 0;
  font-family: "Gelion", sans-serif;
  font-weight: normal;
  line-height: 25px;
  border-left: 3px solid #008ACC;
  margin: 5px 0;
}

@media screen and (max-width: 959px) {
  .alert {
    width: calc(100% - 2px) !important;
    margin-left: 10px;
  }
}
@media screen and (min-width: 960px) {
  .alert {
    width: calc(100% + 18px) !important;
  }
}

.alert-caption p {
  font-size: 16px;
}

.alert-caption p a {
  text-decoration: none;
  font-weight: 600;
  color: #008ACC;
}
.alert .icon-alert {
  font-size: 40px;
  color: #002449;
}

.alert-caption p b {
  color: #008ACC;
}

.alert .icon-close {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 2;
  padding: 1.25rem 1rem;
}

.btn-nano-gray-light {
  width: 40px;
  height: 40px;
  line-height: 40px;
  outline: none;
  display: inline-block;
  text-decoration: none;
  background: #E5E5E5;
  color: #FFF;
  border: 0;
  text-align: center;
  border-radius: 50%;
  cursor: pointer;
  color: #002449;
}

.alert-list-nb {
  list-style: none;
  padding: 0;
  margin: 0;
}

.alert-list-nb .alert-item-nb {
  font-family: "Gelion", sans-serif;
  font-size: 16px;
  font-weight: 400;
}
