
@import './form-alert.scss';
@import './form-buttton.scss';
@import './form-field.scss';
@import './form-file-up.scss';
@import './form-select.scss';






@media screen and (max-width: 959px) {
  .padding-form {
    padding: 10px !important;
  }
}

@media screen and (min-width: 960px) {
  .padding-form {
    padding: 40px !important;
    padding-right: 30px !important;
  }
}

.ml-1 {
  margin-left: 8px !important;
}

.my-1 {
  margin-top: 8px !important;
  margin-bottom: 8px !important;
}

.mb-3 {
  margin-bottom: 24px !important;
}

.text-secondary {
  color: #002449 !important;
}

.sub-title-1 {
  font-family: "Gelion", sans-serif;
  font-size: 20px;
  font-weight: 400;
  line-height: 28px;
  margin: 0px;
}

.sub-title-2 {
  font-family: "Gelion", sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  margin-top: 0px !important;
}

.font-weight-bold {
  font-weight: 700 !important;
}

.font-italic {
  font-style: italic !important;
}

.headline-3 {
  font-family: "Gelion", sans-serif;
  font-size: 28px;
  font-weight: 400;
  line-height: 40px;
}

.text-uppercase {
  text-transform: uppercase !important;
}

h2 {
  display: block;
  font-size: 1.5em;
  margin-block-start: 0.83em;
  margin-block-end: 0.83em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  font-weight: bold;
}

h3 {
  display: block;
  font-size: 1.17em;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  font-weight: bold;
}

h4 {
  display: block;
  margin-block-start: 1.33em;
  margin-block-end: 1.33em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  font-weight: bold;
}

div {
  display: block;
}

a {
  background-color: transparent;
}

.form-group {
  position: relative;
  padding: 8px 0px;
}

@media (max-width: 959px) {
  .form-group {
      padding: 2% 10px;
  }
}

.form-group .label {
  display: block;
  position: absolute;
  top: 0px;
  left: 16px;
  background: #FFF;
  padding: 0 5px;
  color: #898D95;
  font-family: "Gelion", sans-serif;
  font-weight: 400;
  font-size: inherit;
}

.form-group.active .label {
  color: #008ACC !important;
}

label {
  cursor: default;
}

.form-group .input {
  width: 100%;
  border: none;
  border: 1px solid #E5E5E5;
  border-radius: 5px;
  height: 40px;
  padding: 8px;
  background: #FFF;
  color: #898D95;
}

button, input {
  overflow: visible;
}

button, input, optgroup, select, textarea {
    font-family: inherit;
    font-size: 100%;
    line-height: 1.15;
    margin: 0;
}
