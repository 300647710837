
::ng-deep .mat-form-field {
  font-family: "Gelion", sans-serif !important;
}

::ng-deep .mat-form-field-appearance-legacy .mat-form-field-underline {
  height: 0px !important;
  bottom: 0px !important;
}

::ng-deep .mat-form-field-infix {
  padding: 0px !important;
  border-top: 0px !important;
}

::ng-deep .mat-form-field-appearance-legacy .mat-form-field-wrapper {
  padding-bottom: 0px !important;
}

::ng-deep .mat-form-field-appearance-legacy .mat-form-field-infix {
  padding: 0px !important;
}

::ng-deep .mat-form-field-underline {
  display: none !important;
}

::ng-deep .mat-form-field-hint-wrapper {
  display: none !important;
}

::ng-deep .mat-form-field-subscript-wrapper {
  display: none !important;
}

::ng-deep .mat-input-element {
  border: 1px solid #008ACC !important;
  color: #002449 !important;
  width: 100%;
  border: none;
  border-radius: 5px;
  padding: 8px !important;
  background: #FFF;
  //color: #898D95;
  min-width: 100%;
  min-height: 100%;
  max-width:none !important;
  max-height:none !important;
}


.mat-input-element-input {
  height: 22px !important;
}
.mat-input-element-textarea {
  min-height: 66px !important;
  resize:both !important;
}
