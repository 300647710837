
.fileUpGroup {
  position: relative;
  //width: 100%;
  height: 60px;
  cursor: pointer;
  margin: 5px 0;
}

@media screen and (max-width: 959px) {
  .fileUpGroup {
    width: calc(100% - 2px) !important;
    margin-left: 10px;
  }
}
@media screen and (min-width: 960px) {
  .fileUpGroup {
    width: calc(100% + 18px) !important;
  }
}

.fileUpGroup input {
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 3;
  height: 40px;
  margin: 0;
  padding: 0;
  display: block;
  cursor: pointer;
  width: 100%;
}

.fileUpGroup::before {
  content: attr(data-textButton);
  position: absolute;
  top: 0;
  left: 0;
  display: inline-block;
  background: #008ACC;
  color: #FFF;
  z-index: 2;
  line-height: 40px;
  padding: 0 35px;
  pointer-events: none;
  border-radius: 5px 0 0 5px;
}

.fileUpGroup::after {
  content: attr(data-SelectFile);
  position: absolute;
  display: block;
  top: 0;
  right: 0;
  width: calc(100% - 40px);
  height: 40px;
  font-size: 16px;
  font-weight: normal;
  text-align: center;
  padding: 0px 15px;
  background: #E5E5E5;
  border-radius: 5px;
  line-height: 40px;
  color: #008ACC;
  z-index: 1;
  pointer-events: none;
}
