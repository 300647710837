
.button {
  font-family: "Gelion", sans-serif;
  font-size: 16px;
  font-weight: normal;
  text-align: center;
  display: inline-block;
  outline: none;
  border-radius: 5px;
  padding: 10px 35px;
  -webkit-transition: .7s;
  transition: .7s;
  text-decoration: none;
  cursor: pointer;
}

.button-border {
  border-style: none !important;
}

@media screen and (max-width: 959px) {
  .button-border {
    margin-left: 10px;
  }
}

.button-primary {
  color: #FFF !important;
  background-color: #008ACC;
  border: 2px solid #008ACC;
  -webkit-transition: .7s;
  transition: .7s;
}

.button-disabled {
  color: #FFF !important;
  background-color: #939393;
  border: 2px solid #939393;
  -webkit-transition: .7s;
  transition: .7s;
}
