@charset "UTF-8";
// Importaciones de fuentes de Landing Page

@font-face {
  font-family: "bocc-icons";
  src:  url("./../fonts/bocc-icons/bocc-icons.eot?x2y35s");
  src:  url("./../fonts/bocc-icons/bocc-icons.eot?x2y35s#iefix") format("embedded-opentype"),
        url("./../fonts/bocc-icons/bocc-icons.svg?x2y35s#bocc-icons") format("svg"),
        url("./../fonts/bocc-icons/bocc-icons.ttf?x2y35s") format("truetype"),
        url("./../fonts/bocc-icons/bocc-icons.woff?x2y35s") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

@font-face {
  font-family: 'Gelion';
  src:  url("./../fonts/Gelion-Black/Gelion-Black.eot");
  src:  url("./../fonts/Gelion-Black/Gelion-Black.eot?#iefix") format("embedded-opentype"),
        url("./../fonts/Gelion-Black/Gelion-Black.ttf") format("truetype"),
        url("./../fonts/Gelion-Black/Gelion-Black.woff") format("woff"),
        url("./../fonts/Gelion-Black/Gelion-Black.woff2") format("woff2");
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Gelion';
  src:  url("./../fonts/Gelion-Bold/Gelion-Bold.eot");
  src:  url("./../fonts/Gelion-Bold/Gelion-Bold.eot?#iefix") format("embedded-opentype"),
        url("./../fonts/Gelion-Bold/Gelion-Bold.ttf") format("truetype"),
        url("./../fonts/Gelion-Bold/Gelion-Bold.woff") format("woff"),
        url("./../fonts/Gelion-Bold/Gelion-Bold.woff2") format("woff2");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Gelion';
  src:  url("./../fonts/Gelion-Light/Gelion-Light.eot");
  src:  url("./../fonts/Gelion-Light/Gelion-Light.eot?#iefix") format("embedded-opentype"),
        url("./../fonts/Gelion-Light/Gelion-Light.ttf") format("truetype"),
        url("./../fonts/Gelion-Light/Gelion-Light.woff") format("woff"),
        url("./../fonts/Gelion-Light/Gelion-Light.woff2") format("woff2");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Gelion';
  src:  url("./../fonts/Gelion-Medium/Gelion-Medium.eot");
  src:  url("./../fonts/Gelion-Medium/Gelion-Medium.eot?#iefix") format("embedded-opentype"),
        url("./../fonts/Gelion-Medium/Gelion-Medium.ttf") format("truetype"),
        url("./../fonts/Gelion-Medium/Gelion-Medium.woff") format("woff"),
        url("./../fonts/Gelion-Medium/Gelion-Medium.woff2") format("woff2");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Gelion';
  src:  url("./../fonts/Gelion-Regular/Gelion-Regular.eot");
  src:  url("./../fonts/Gelion-Regular/Gelion-Regular.eot?#iefix") format("embedded-opentype"),
        url("./../fonts/Gelion-Regular/Gelion-Regular.ttf") format("truetype"),
        url("./../fonts/Gelion-Regular/Gelion-Regular.woff") format("woff"),
        url("./../fonts/Gelion-Regular/Gelion-Regular.woff2") format("woff2");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Gelion';
  src:  url("./../fonts/Gelion-SemiBold/Gelion-SemiBold.eot");
  src:  url("./../fonts/Gelion-SemiBold/Gelion-SemiBold.eot?#iefix") format("embedded-opentype");
  src:  url("./../fonts/Gelion-SemiBold/Gelion-SemiBold.ttf") format("truetype"),
        url("./../fonts/Gelion-SemiBold/Gelion-SemiBold.woff") format("woff"),
        url("./../fonts/Gelion-SemiBold/Gelion-SemiBold.woff2") format("woff2");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Gelion';
  src:  url("./../fonts/Gelion-Thin/Gelion-Thin.eot");
  src:  url("./../fonts/Gelion-Thin/Gelion-Thin.eot?#iefix") format("embedded-opentype"),
        url("./../fonts/Gelion-Thin/Gelion-Thin.ttf") format("truetype"),
        url("./../fonts/Gelion-Thin/Gelion-Thin.woff") format("woff"),
        url("./../fonts/Gelion-Thin/Gelion-Thin.woff2") format("woff2");
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "icons";
  src:  url("./../fonts/icons/icons.eot?#iefix") format("eot");
  src:  url("./../fonts/icons/icons.svg") format("svg"),
        url("./../fonts/icons/icons.ttf") format("truetype"),
        url("./../fonts/icons/icons.woff") format("woff");
}
