::ng-deep .mat-select {
  font-family: "Gelion", sans-serif !important;
  width: calc(100% + 18px) !important;
  border: 1px solid #008ACC !important;
  border-radius: 5px !important;
  height: 40px !important;
  padding: 8px !important;
  padding-right: 0px !important;
  background: #FFF !important;
  color: #002449 !important;
}

::ng-deep .mat-select-arrow {
  opacity: 0;
}

::ng-deep .mat-select-arrow-wrapper {
  padding-left: 28px !important;
  background-image: url(./../../../assets/img/icon-desplegar.png) !important;
  background-repeat: no-repeat !important;
  background-position-y: top;
  background-position-x: right;
}

::ng-deep .mat-select-panel {
  border-radius: none !important;
  border: 1px solid rgb(115, 115, 115) !important;
}

::ng-deep .mat-select-panel .mat-option {
  background-color: white;
  border: none !important;
}

::ng-deep .mat-select-panel .mat-option.mat-selected:not(.mat-option-multiple) {
  background: rgba(0,0,0,.12);
  font-style: italic;
}

::ng-deep .mat-option {
  background-color: #fff;
  color: #002449 !important;
}

::ng-deep {
  .mat-option-text:hover:not(.mat-option-disabled) {
    font-family: "Gelion", sans-serif !important;
    font-size: 16px;
    //background: #3071a9 !important;
    background: #008acc !important;
    color: white;
  }

  .mat-option:hover {
    font-family: "Gelion", sans-serif !important;
    font-size: 16px;
    background: #008acc !important;
    color: white !important;
  }
}
