/* You can add global styles to this file, and also import other style files */

//Importaciones

@import "~@angular/material/prebuilt-themes/indigo-pink.css";
@import 'material-icons/iconfont/material-icons.css';
@import './assets/styles/fonts.scss';
@import './assets/styles/icons.scss';
@import './assets/styles/forms/form.scss';
@import '~ngx-owl-carousel-o/lib/styles/scss/owl.carousel';
@import '~ngx-owl-carousel-o/lib/styles/scss/owl.theme.default';


html {
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
}

:root {
  --font-size: 1;
  --algo: 0px !important;
}

body {
  font-family: "Gelion", sans-serif;
  background: #FFF !important;
  font-size: calc(var(--font-size) * 1em);
  position: relative;
  padding-bottom: 13.15em;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
}

.text-primary {
  color: #008ACC !important;
}

.separator-header {
  height:4px; clear:both;
}

.separator-footer {
  height:16px; clear:both;
}
input.invalid.mat-input-element {
  border-color: red !important;
}
.invalid-textarea.mat-input-element-textarea {
  border-color: red !important;
}
.invalid-input {
  border: 1px solid red;
}

.invalid-text {
  color: red;
  font-size: small;
}
.alert-text {
  color: red;
  margin-top: -17px;
  position: relative;
  font-size: small;
}
.fileUpGroup {
  margin-bottom: 10px;
}

.file-list {
  margin-top: 5px;
}

.file-item {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
}

.file-info {
  display: flex;
  align-items: center;
}

.delete-button {
  color: #008ACC;
  margin-left: 10px; /* Ajusta el margen según sea necesario */
  background: none; /* Elimina el fondo del botón */
  border: none; /* Elimina el borde del botón */
  cursor: pointer; /* Cambia el cursor al pasar sobre el botón */
  padding: 0;
}
.file-info mat-icon {
  margin-right: 5px; /* O ajusta según sea necesario */
}
.file-item:hover .file-info {
  color: #008ACC;
}

